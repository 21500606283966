import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import DeleteIcon from "@nokia-csf-uxr/ccfk-assets/legacy/DeleteIcon";

import ActionBtns from "Components/Elements/ActionBtns";
import QuantityModifier from "Components/Elements/QuantityModifier";
import { RootState } from "Store/mainStore";
import { roundCount } from "Utils";
import { addCartItem, removeCartItem } from "Store/contentState/cartBoqSlice";
import useDataGrid from "./useDataGrid";
import { setProp } from "Store/Actions";


export const BoqDetails = () => {
  const caseDetailsSelector = useSelector(
    (state: RootState) => state.caseDetails
  );
  const cartBoqSelector = useSelector(
    (state: RootState) => state.undoCartBoq.present
  );
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const businessSelector = useSelector((state: RootState) => state.business);
  const warrantySelector = useSelector((state: RootState) => state.warranty);

  const dispatch = useDispatch();
  const { getInlineRowActionsColumnDefs } = useDataGrid();
  

  /**
   * Renders a QuantityModifier component for a given product, allowing the user to update the quantity.
   * Dispatches actions to add or remove items from the cart based on the quantity change.
   *
   * @param {Object} params - The parameters containing the product data and current quantity.
   * @returns {JSX.Element} The rendered QuantityModifier component.
   */
  const getQuantityGridColumn = (params) => {
    return (
      <QuantityModifier
        product={params.data}
        onUpdateQty={(val) =>
          Math.sign(params.value - val) == -1
            ? dispatch(
                addCartItem(
                  new Object({
                    ...params.data,
                    quantity: Math.abs(params.value - val),
                  })
                )
              )
            : dispatch(
                removeCartItem(
                  new Object({
                    ...params.data,
                    quantity: Math.abs(params.value - val),
                  })
                )
              )
        }
        onDecQty={() =>
          dispatch(removeCartItem(new Object({ ...params.data, quantity: 1 })))
        }
        onIncQty={() =>
          dispatch(addCartItem(new Object({ ...params.data, quantity: 1 })))
        }
        value={Math.round(params.value)}
      />
    );
  };

  /**
   * Returns column definitions for a delete action button in a data grid.
   * If the BoQ is editable, it includes a delete button with an onClick handler.
   *
   * @param {string} idKey - The unique identifier key for the delete action.
   * @returns {Object} The column definitions for the delete action button.
   */
  const getDeleteGridColumn = (idKey) => {
    if (!cartBoqSelector.data.isBoQEditable) return {};
    return getInlineRowActionsColumnDefs("rowActionColId", [
      {
        id: `delete_${idKey}`,
        ariaLabel: `Delete-action-${idKey}`,
        icon: <DeleteIcon />,
        tooltip: "Delete",
        onClick: (params) => {
          // dispatch action to set the delete flag for deleting the item from the cart
          dispatch(setProp({ slice: "cartBoq", key: "boQDeleteKey", value: params.data }));
        },
      },
    ]);
  };

  const cmuBoqGrid = (boqdata) => {
    const hardwareData = boqdata.filter((e) => e.type === "hardware");
    const softwareData = boqdata.filter((e) => e.type === "software");
    let fieldToShow = "product_name";
    let displaySolutionTitle = "Work Item";
    let end_end_servicesData = boqdata.filter(
      (e) => e.type === "end_end_services"
    );

    /**** Important Note  **** 
    if its cmu show title and value as work package , and has to group the servcies on work package 
    ***/
    if (configstepperSelector.data.solution_selected === "cmu") {
      fieldToShow = "work_package";
      displaySolutionTitle = "Work Package";
      end_end_servicesData = get_ServiceDataGroup(
        boqdata.filter((e) => e.type === "end_end_services")
      );
    }
    const product_attached_service_data = get_ServiceDataGroup(
      boqdata.filter((e) => e.type === "product_attached_services")
    );
    const careData = boqdata.filter((e) => e.type === "care");
    const srsData = boqdata.filter((e) => e.type === "srs");

    const cmuGrid = [
      {
        label: "Hardware",
        id_key: "hardware",
        overlayNoRowsTemplate:
          "No Hardware products available for the current selection",
        rowData: hardwareData,
        columnDefs: [
          {
            headerName: "SI Code",
            field: "product_id",
            cellStyle: {
              borderLeft: "3px solid",
              borderLeftColor: "var(--g-color-app-banner-background)",
            },
            flex: 1,
          },

          { headerName: "Description", field: "product_name", flex: 2 },
          {
            headerName: "",
            field: "",
            cellRendererFramework: (params) => <div></div>,
          },
          // {
          //   headerName: "Quantity",
          //   field: "quantity",
          // },
          {
            headerName: "Quantity",
            field: "quantity",
            minWidth: cartBoqSelector.data.isBoQEditable ? 160 : 50,
            cellRendererFramework: (params) =>
              cartBoqSelector.data.isBoQEditable ? (
                <div
                  style={{
                    width: "140px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {getQuantityGridColumn(params)}
                </div>
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {params.data?.["quantity"]}
                </div>
              ),
          },
          {
            headerName: `Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            flex: 1,
            cellRendererFramework: (params) => (
              <div style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
          { ...getDeleteGridColumn("products") },
        ],
      },
      {
        label: "Software",
        id_key: "software",
        overlayNoRowsTemplate:
          "No Software products available for the current selection",
        rowData: softwareData,
        columnDefs: [
          {
            headerName: "SI Code",
            field: "product_id",
            cellStyle: {
              borderLeft: "3px solid",
              borderLeftColor: "var(--g-color-app-banner-background)",
            },
            minWidth: 140,
          },

          {
            headerName: "Description",
            field: "product_name",
            minWidth: 240,
            flex: 2,
          },
          {
            headerName: "Duration (Years)",
            field: "duration",
            minWidth: 115,
          },
          // {
          //   headerName: "Quantity",
          //   field: "quantity",
          // },
          {
            headerName: "Quantity",
            field: "quantity",
            minWidth: cartBoqSelector.data.isBoQEditable ? 160 : 50,
            cellRendererFramework: (params) =>
              cartBoqSelector.data.isBoQEditable ? (
                <div
                  style={{
                    width: "140px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {getQuantityGridColumn(params)}
                </div>
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {params.data?.["quantity"]}
                </div>
              ),
          },
          {
            headerName: `Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            minWidth: 115,
            cellRendererFramework: (params) => (
              <div style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
          { ...getDeleteGridColumn("products") },
        ],
      },
      {
        label: "Solution Services",
        id_key: "end_end_services",
        overlayNoRowsTemplate:
          "No Solution services products available for the current selection",
        rowData: end_end_servicesData,
        columnDefs: [
          { headerName: displaySolutionTitle, field: fieldToShow, flex: 3 },
          {
            headerName: `Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            cellRendererFramework: (params) => (
              <div id={params} style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
        ],
      },
      {
        label: "Product Attached Services",
        id_key: "product_attached_services",
        overlayNoRowsTemplate:
          "No Services products available for the current selection",
        rowData: product_attached_service_data,
        columnDefs: [
          { headerName: "Work Package", field: "work_package", flex: 3 },

          {
            headerName: `Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            minWidth: 115,
            cellRendererFramework: (params) => (
              <div style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
        ],
      },
      {
        label: "Care",
        id_key: "care",
        overlayNoRowsTemplate:
          "No Care products available for the current selection",
        rowData: careData,
        columnDefs: [
          {
            headerName: "SI Code",
            field: "product_id",
            cellStyle: {
              borderLeft: "3px solid",
              borderLeftColor: "var(--g-color-app-banner-background)",
            },
            minWidth: 140,
          },

          {
            headerName: "Description",
            field: "product_name",
            minWidth: 240,
            flex: 2,
          },
          {
            headerName: "Duration (Years)",
            field: "duration",
            minWidth: 115,
          },
          // {
          //   headerName: "Quantity",
          //   field: "quantity",
          // },
          {
            headerName: "Quantity",
            field: "quantity",
            minWidth: cartBoqSelector.data.isBoQEditable ? 160 : 50,
            cellRendererFramework: (params) =>
              cartBoqSelector.data.isBoQEditable ? (
                <div
                  style={{
                    width: "140px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {getQuantityGridColumn(params)}
                </div>
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {params.data?.["quantity"]}
                </div>
              ),
          },
          {
            headerName: `Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            minWidth: 115,
            cellRendererFramework: (params) => (
              <div style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
          { ...getDeleteGridColumn("products") },
        ],
      },
      {
        label: "SRS",
        id_key: "srs",
        overlayNoRowsTemplate: "No SRS available for the current selection",
        rowData: srsData,
        columnDefs: [
          {
            headerName: "SI Code",
            field: "product_id",
            cellStyle: {
              borderLeft: "3px solid",
              borderLeftColor: "var(--g-color-app-banner-background)",
            },
            minWidth: 140,
          },
          {
            headerName: "Description",
            field: "product_name",
            minWidth: 240,
            flex: 2,
          },
          {
            headerName: "Duration (Years)",
            field: "duration",
            minWidth: 115,
          },
          // {
          //   headerName: "Quantity",
          //   field: "quantity",
          // },
          {
            headerName: "Quantity",
            field: "quantity",
            minWidth: cartBoqSelector.data.isBoQEditable ? 160 : 50,
            cellRendererFramework: (params) =>
              cartBoqSelector.data.isBoQEditable ? (
                <div
                  style={{
                    width: "140px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {getQuantityGridColumn(params)}
                </div>
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {params.data?.["quantity"]}
                </div>
              ),
          },
          {
            headerName: `Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            cellRendererFramework: (params) => (
              <div style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
          { ...getDeleteGridColumn("products") },
        ],
      },
    ];
    return cmuGrid;
  };

  const servicesGrid = (boqdata) => {
    const services_data = boqdata.filter(
      (e) =>
        e.category !== "sla" &&
        (e.type === "service" || e?.cui_type === "service")
    );
    const serviceboq = [
      {
        label: "Services",
        id_key: "services",
        overlayNoRowsTemplate:
          "No Services available for the current selection",
        rowData: services_data,
        columnDefs: [
          { headerName: "Product ID", field: "product_id" },
          { headerName: "Description", field: "product_name", flex: 2 },
          // {
          //   headerName: "Quantity",
          //   field: "quantity",
          // },
          {
            headerName: "Quantity",
            field: "quantity",
            minWidth: cartBoqSelector.data.isBoQEditable ? 160 : 50,
            cellRendererFramework: (params) =>
              cartBoqSelector.data.isBoQEditable ? (
                <div
                  style={{
                    width: "140px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {getQuantityGridColumn(params)}
                </div>
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {params.data?.["quantity"]}
                </div>
              ),
          },
          {
            headerName: `List Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            cellRendererFramework: (params) => (
              <div id={params} style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
          { ...getDeleteGridColumn("products") },
        ],
      },
    ];
    return serviceboq;
  };

  const referenceBoqGrid = (boqdata) => {
    const productGrid = productCommonGrid(boqdata);
    const sla_warrGrid = slawarrGrid(boqdata);
    const service_grid = servicesGrid(boqdata);

    const end_end_servicesData = boqdata.filter(
      (e) => e.type === "end_end_services"
    );

    const solution_servicesGrid = [
      {
        label: "Solution Services",
        id_key: "end_end_services",
        overlayNoRowsTemplate:
          "No Solution services products available for the current selection",
        rowData: end_end_servicesData,
        columnDefs: [
          { headerName: "Work Package", field: "work_package", flex: 3 },
          {
            headerName: `Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            cellRendererFramework: (params) => (
              <div id={params} style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
        ],
      },
    ];

    const refGrid = [
      ...productGrid,
      ...service_grid,
      ...solution_servicesGrid,
      ...sla_warrGrid,
    ];

    return refGrid;
  };

  const cdmBoqGrid = (boqdata) => {
    return referenceBoqGrid(boqdata);
  };

  const productCommonGrid = (boqdata) => {
    const product_data = boqdata.filter((e) => e.type === "product");
    const subscription_license_data = boqdata.filter(
      (e) => e.category !== "warranty" && e.type === "subscription"
    );

    const portGrid = [
      {
        label: "Products",
        id_key: "products",
        overlayNoRowsTemplate: "No Product available for the current selection",
        rowData: product_data,
        columnDefs: [
          {
            headerName: "Product ID",
            field: "product_id",
            cellStyle: {
              borderLeft: "3px solid",
              borderLeftColor: "var(--g-color-app-banner-background)",
            },
            flex: 1,
          },
          { headerName: "Description", field: "product_name", flex: 2 },
          {
            headerName: "Quantity",
            field: "quantity",
            minWidth: cartBoqSelector.data.isBoQEditable ? 160 : 50,
            cellRendererFramework: (params) =>
              cartBoqSelector.data.isBoQEditable ? (
                <div
                  style={{
                    width: "140px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {getQuantityGridColumn(params)}
                </div>
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {params.data?.["quantity"]}
                </div>
              ),
          },
          {
            headerName: `List Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            flex: 1,
            cellRendererFramework: (params) => (
              <div style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
          { ...getDeleteGridColumn("products") },
        ],
      },
      {
        label: "Subscriptions and licenses",
        id_key: "subscription",
        overlayNoRowsTemplate:
          "No Subscription or License available for the current selection",
        rowData: subscription_license_data,
        columnDefs: [
          {
            headerName: "Product ID",
            field: "product_id",
            cellStyle: {
              borderLeft: "3px solid",
              borderLeftColor: "var(--g-color-app-banner-background)",
            },
            minWidth: 140,
          },
          {
            headerName: "Description",
            field: "product_name",
            minWidth: 240,
            flex: 2,
          },
          {
            headerName: "Duration (Months)",
            field: "duration",
            minWidth: 115,
            //flex: 1,
            cellRendererFramework: (params) => (
              <div>
                {businessSelector.data.view.PTDAC.commercial_model === "capex"
                  ? businessSelector.data.view.PTDAC.contract_duration * 12
                  : businessSelector.data.view.PTDAC.contract_duration}
              </div>
            ),
          },
          // {
          //   headerName: "Quantity",
          //   field: "quantity",
          // },
          {
            headerName: "Quantity",
            field: "quantity",
            minWidth: cartBoqSelector.data.isBoQEditable ? 160 : 50,
            cellRendererFramework: (params) =>
              cartBoqSelector.data.isBoQEditable ? (
                <div
                  style={{
                    width: "140px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {getQuantityGridColumn(params)}
                </div>
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {params.data?.["quantity"]}
                </div>
              ),
          },
          {
            headerName: `List Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            minWidth: 115,
            cellRendererFramework: (params) => (
              <div style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
          { ...getDeleteGridColumn("products") },
        ],
      },
    ];

    return portGrid;
  };

  const slawarrGrid = (boqdata) => {
    const sla_data = boqdata.filter((e) => e.category === "sla");
    const warranty_data = boqdata.filter(
      (e) => e.category === "warranty" || e.type === "warranty"
    );
    let slaDuration = warrantySelector.data.options.PTDAC.subscription_duration;
    let warrantyDuration = warrantySelector.data.options.PTDAC.warranty[1]
      ? warrantySelector.data.options.PTDAC.warranty[1]
      : 0;
    let duration_sla = "Duration (Months)";

    if (
      dynamicSelector.data.dataSet["subscription_duration_months_opex"] ||
      dynamicSelector.data.dataSet["subscription_duration_years_capex"] ||
      dynamicSelector.data.dataSet["subscription_duration_months_operating_lease"]
    ) {
      slaDuration = get_slaDuration_basedOnCommercialModel();
    } else {
      slaDuration = 1;
    }
    // set the title
    duration_sla =
      businessSelector.data.view.PTDAC.commercial_model === "opex"
        ? "Duration (Months)"
        : "Duration (Years)";

    // warranty duration
    if (dynamicSelector.data.dataSet["extend_warranty_by"]) {
      warrantyDuration = dynamicSelector.data.dataSet["extend_warranty_by"];
    } else {
      warrantyDuration = 1;
    }

    const slaWarrGrid = [
      {
        label: "SLA",
        id_key: "sla",
        overlayNoRowsTemplate:
          "No SLA or Warranty products available for the current selection",
        rowData: sla_data,
        columnDefs: [
          {
            headerName: "Product ID",
            field: "product_id",
            cellStyle: {
              borderLeft: "3px solid",
              borderLeftColor: "var(--g-color-app-banner-background)",
            },
            minWidth: 140,
          },
          {
            headerName: "Description",
            field: "product_name",
            minWidth: 240,
            flex: 2,
          },
          {
            headerName: `${duration_sla}`,
            field: "duration",
            minWidth: 115,
            cellRendererFramework: (params) => (
              <div>
                {params.data.category === "sla"
                  ? slaDuration
                  : params.data.category === "warranty" ||
                    params.data.type === "warranty"
                  ? warrantyDuration
                  : 0}
              </div>
            ),
          },
          // {
          //   headerName: "Quantity",
          //   field: "quantity",
          // },
          {
            headerName: "Quantity",
            field: "quantity",
            minWidth: cartBoqSelector.data.isBoQEditable ? 160 : 50,
            cellRendererFramework: (params) =>
              cartBoqSelector.data.isBoQEditable ? (
                <div
                  style={{
                    width: "140px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {getQuantityGridColumn(params)}
                </div>
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {params.data?.["quantity"]}
                </div>
              ),
          },
          {
            headerName: `List Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            minWidth: 115,
            cellRendererFramework: (params) => (
              <div style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
          { ...getDeleteGridColumn("products") },
        ],
      },
      {
        label: "Warranty",
        id_key: "warranty",
        overlayNoRowsTemplate:
          "No SLA or Warranty products available for the current selection",
        rowData: warranty_data,
        columnDefs: [
          {
            headerName: "Product ID",
            field: "product_id",
            cellStyle: {
              borderLeft: "3px solid",
              borderLeftColor: "var(--g-color-app-banner-background)",
            },
            minWidth: 140,
          },

          {
            headerName: "Description",
            field: "product_name",
            minWidth: 240,
            flex: 2,
          },
          {
            headerName: "Duration (Years)",
            field: "duration",
            minWidth: 115,
            cellRendererFramework: (params) => (
              <div>
                {params.data.category === "sla"
                  ? slaDuration
                  : params.data.category === "warranty" ||
                    params.data.type === "warranty"
                  ? warrantyDuration
                  : 0}
              </div>
            ),
          },
          // {
          //   headerName: "Quantity",
          //   field: "quantity",
          // },
          {
            headerName: "Quantity",
            field: "quantity",
            minWidth: cartBoqSelector.data.isBoQEditable ? 160 : 50,
            cellRendererFramework: (params) =>
              cartBoqSelector.data.isBoQEditable ? (
                <div
                  style={{
                    width: "140px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {getQuantityGridColumn(params)}
                </div>
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {params.data?.["quantity"]}
                </div>
              ),
          },
          {
            headerName: `List Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            minWidth: 115,
            cellRendererFramework: (params) => (
              <div style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
          { ...getDeleteGridColumn("products") },
        ],
      },
    ];

    return slaWarrGrid;
  };

  const portBoqGrid = (boqdata) => {
    const productGrid = productCommonGrid(boqdata);
    const sla_warrGrid = slawarrGrid(boqdata);
    const service_grid = servicesGrid(boqdata);
    let spares = [];
    const spareData = boqdata.filter((e) => e.type === "spares");
    const end_end_servicesData = boqdata.filter(
      (e) => e.type === "end_end_services"
    );
    
    if (spareData.length > 0) {
      spares = [
        {
          label: "Spares",
          id_key: "spares",
          overlayNoRowsTemplate:
            "No Spares available for the current selection",
          rowData: spareData,
          columnDefs: [
            {
              headerName: "Product ID",
              field: "cui_product_id",
              cellStyle: {
                borderLeft: "3px solid",
                borderLeftColor: "var(--g-color-app-banner-background)",
              },
              flex: 1,
            },
            { headerName: "Description", field: "product_name", flex: 2 },
            {
              headerName: "Quantity",
              field: "quantity",
              headerClass: "ag-right-aligned-header",
              cellRendererFramework: (params) => (
                <div style={{ width: "100px" }}>
                  <QuantityModifier
                    product={params.data}
                    onUpdateQty={(val) =>
                      Math.sign(params.value - val) == -1
                        ? dispatch(
                            addCartItem(
                              new Object({
                                ...params.data,
                                quantity: Math.abs(params.value - val),
                              })
                            )
                          )
                        : dispatch(
                            removeCartItem(
                              new Object({
                                ...params.data,
                                quantity: Math.abs(params.value - val),
                              })
                            )
                          )
                    }
                    onDecQty={() =>
                      dispatch(
                        removeCartItem(
                          new Object({ ...params.data, quantity: 1 })
                        )
                      )
                    }
                    onIncQty={() =>
                      dispatch(
                        addCartItem(new Object({ ...params.data, quantity: 1 }))
                      )
                    }
                    value={Math.round(params.value)}
                  />
                </div>
              ),
            },
            {
              headerName: `List Price (${caseDetailsSelector.data.currency})`,
              field: "cost",
              cellRendererFramework: (params) => (
                <div style={{ textAlign: "right" }}>
                  {roundCount(params.value)}
                </div>
              ),
              headerClass: "ag-right-aligned-header",
            },
            {
              flex: 0.5,
              hide: !true,
              cellRendererFramework: (params) => (
                <ActionBtns product={params.data} />
              ),
            },
            { ...getDeleteGridColumn("products") },
          ],
        },
      ];
    }

    const solution_servicesGrid = [
      {
        label: "Solution Services",
        id_key: "end_end_services",
        overlayNoRowsTemplate:
          "No Solution services products available for the current selection",
        rowData: end_end_servicesData,
        columnDefs: [
          { headerName: "Work Package", field: "work_package", flex: 3 },
          {
            headerName: `Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            cellRendererFramework: (params) => (
              <div id={params} style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
        ],
      },
    ];
    const portGrid = [
      ...productGrid,
      ...solution_servicesGrid,
      ...service_grid,
      ...sla_warrGrid,
      ...spares,
    ];
    return portGrid;
  };

  const get_ServiceDataGroup = (servicesDataList) => {
    let end_end_services_list = [];
    servicesDataList.forEach((element) => {
      const elemWorkPackage = element.work_package
        ? element.work_package
        : element.workPackage;
      const elemWorkItem = element.work_item
        ? element.work_item
        : element.workItem;
      let aa = elemWorkPackage.split(":");
      let end_end_services_details = {};
      // this cost contains total_pnp_with_incoterm value that is coming from after response calculations in configurator_AddToCart
      end_end_services_details["cost"] = element.cost;
      end_end_services_details["work_package"] = _.trim(aa[0]);
      end_end_services_details["work_item"] = elemWorkItem;
      end_end_services_list.push(end_end_services_details);
    });
    let end_end_groups = {};
    end_end_services_list.forEach((employee) => {
      // let { work_package } = employee;
      if (!end_end_groups[employee.work_package]) {
        end_end_groups[employee.work_package] = [];
      }
      end_end_groups[employee.work_package].push(employee);
    });
    let end_end_servicesData_List = [];
    Object.entries(end_end_groups).map((data, _index) => {
      let data_list = {};
      data_list["work_package"] = data[0];
      data_list["cost"] = end_end_groups[data[0]]
        .map((o) => parseFloat(o.cost))
        .reduce((a, c) => {
          return a + c;
        });
      data_list["work_item"] = end_end_groups[data[0]]
        .map((o) => o.work_item)
        .reduce((a, c) => {
          return a;
        });
      end_end_servicesData_List.push(data_list);
    });
    return end_end_servicesData_List;
  };

  const MPWGrid = (boqdata) => {
    // MPW + NDAC boq data
    const productGrid = productCommonGrid(boqdata);
    const sla_warrGrid = slawarrGrid(boqdata);
    const cmuGrid = cmuBoqGrid(boqdata);
    const refGrid = [...productGrid, ...cmuGrid, ...sla_warrGrid];

    return refGrid;
  };

  const get_slaDuration_basedOnCommercialModel = () => {
    const sla_dur = dynamicSelector.data.dataSet[
      "subscription_duration_months_opex"
    ]
      ? dynamicSelector.data.dataSet["subscription_duration_months_opex"]
      : (dynamicSelector.data.dataSet["subscription_duration_years_capex"] ? dynamicSelector.data.dataSet["subscription_duration_years_capex"] : dynamicSelector.data.dataSet["subscription_duration_months_operating_lease"]);

    return sla_dur;
  }

  return {
    portBoqGrid,
    referenceBoqGrid,
    cmuBoqGrid,
    cdmBoqGrid,
    MPWGrid,
    get_slaDuration_basedOnCommercialModel
  };
};