/**
 * FullSolutionsView is a React functional component that renders the full solutions view for the business configurator.
 * It manages the state and interactions for selecting solution types, commercial models, and contract durations.
 * 
 * The component uses Redux for state management and includes several hooks for handling side effects and state updates.
 * 
 * Key functionalities include:
 * - Loading and displaying reference solution buttons.
 * - Handling changes to the selected solution type and updating the state accordingly.
 * - Loading and displaying commercial model options.
 * - Handling changes to the selected commercial model and updating the state accordingly.
 * - Displaying and managing a slider for contract duration based on the selected commercial model.
 * 
 * The component also includes several utility functions for loading data, resetting states, and converting values.
 */
import { HorizontalDivider, RadioButton } from "@nokia-csf-uxr/ccfk";
import { RadioButtonLabelContent } from "@nokia-csf-uxr/ccfk/RadioButton";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { commericalModel } from "Components/Content/configurationArea/Business/system";
import SliderComponent from "Components/Elements/Slider";
import { setProp } from "Store/Actions";
import { RootState, store } from "Store/mainStore";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSolutionLoad } from "../../Configurator/hooks/useSolutionLoad";
import { setDataConfig } from "Store/contentState/configuration_stepperSlice";
import _ from "lodash";

const FullSolutionsView = () => {
  /** Store selectors */
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const configStepper = useSelector((state: RootState) => state.config_stepper);
  const fullSolution = useSelector(
    (state: RootState) => state.business.data.options.PTDAC.caseIndustry
  );
  const businessSelector = useSelector((state: RootState) => state.business);
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );

  /** Hooks */
  const dispatch = useDispatch();
  const {
    referenceSolutionButtons,
    updateSolutionData,
    getSegmentDetails,
    setNDACCompact,
  } = useSolutionLoad();

  /** Component States */
  const [commercialDuration, setCommercialDuration] = useState(true);
  const [commericial_contractDurInfo, setCommercialContractDuration] =
    useState(commericalModel);
  
  /** Reset commercial model and duration */
  const resetCommercialModelAndDuration = useCallback(() => {
    dispatch(
      setProp({
        slice: "dynamic",
        key: "reference_solution.commercial_model",
        value: "Opex",
      })
    );
    onChangeDuration();
  }, []);

  /** On component load -
   * calls resetCommercialModelAndDuration and setCommercialContractDuration
   */
  useEffect(() => {    
    if (
      commericial_contractDurInfo.byIds[
        dynamicSelector.data.reference_solution.commercial_model.toLocaleLowerCase()
      ] === undefined
    ) {
      resetCommercialModelAndDuration();
      setCommercialContractDuration(commericalModel);
    }
  }, []);

  useEffect(() => {
    loadCommericalContractUIData();
    onChangeDuration();    
  }, [
    businessSelector.data.options.PTDAC.caseIndustry,
    configstepperSelector.data.solutionType,
    businessSelector.data.options.PTDAC.ndac_ref.fullSolType,
    businessSelector.data.options.PTDAC.ndac_ref.fullMiningType,
  ]);

  useEffect(() => {
    if (dynamicSelector.data.reference_solution.commercial_model === "operating_lease") {
      updateSolutionData("ndac_compact");
    }
  }, [dynamicSelector.data.reference_solution.commercial_model]);


  /**
 * Loads the commercial contract UI data based on the provided reference solution and commercial model name.
 * If no parameters are provided, defaults to "Opex" model and the current reference solution.
 * Updates the state with the fetched commercial contract duration information.
 *
 * @param {string} [refsoln] - The reference solution to load data for.
 * @param {string} [commercialModelName] - The name of the commercial model to load data for.
 */
  const loadCommericalContractUIData = async (
    refsoln?,
    commercialModelName?
  ) => {
    const model = commercialModelName ? commercialModelName : "Opex";
    dispatch(
      setProp({
        slice: "dynamic",
        key: "reference_solution.commercial_model",
        value: model,
      })
    );
    const referenceSol = refsoln
      ? refsoln
      : dynamicSelector.data.reference_solution.solution;
    if (fullSolution === "reference_solution" && referenceSol === "NDAC") {
      const commericial_contractDurInfo = await setNDACCompact();
      commericial_contractDurInfo &&
        setCommercialContractDuration(commericial_contractDurInfo);
    } else {
      setCommercialContractDuration(commericalModel);
    }
  };

  /** reference_solution_buttons onchange */
  const buttonSelect = async (option) => {
    dispatch(
      setProp({
        slice: "dynamic",
        key: "reference_solution.solution",
        value: option.value,
      })
    );
    // onchange of solution type, reset the commercial model and contract duration
    resetCommercialModelAndDuration();
    let segmentLoadStatus = true; // by default load the solution data
    let csId = `${option.value.toLowerCase()}_${fullSolution}`;
    if (_.includes(["manufacturing", "port"], fullSolution)) {
      csId = configStepper.data.solution_selected;
      // if selected option is mpw then set only business in stepper data and dont call solutionlaod
      if (option.value === "MPW") {
        segmentLoadStatus = false;
        const cs = getSegmentDetails(csId);
        const intialStep = [
          {
            name: "Business",
            stepNumber: 1,
            status: "current",
            isComplete: false,
            selected: true,
            isValid: true,
            id: "business",
            stepname: "business",
          },
        ];
        const dataConfig = [
          { key: "solution_selected", value: cs.segment },
          { key: "isStepperLoading", value: false },
          { key: "solutionCategory", value: "MPW" },
          { key: "solutionType", value: cs.solutionType },
          { key: "stepper_data", value: intialStep },
        ];
        dispatch(setDataConfig(dataConfig));
      }
    }
    loadCommericalContractUIData(option.value);

    if (segmentLoadStatus) {
      // Load the solution steps
      updateSolutionData(csId);
    }
  };

  /**
 * Handles the change of the commercial model option.
 * Updates the state and dispatches actions to set the new commercial model and contract duration.
 *
 * @param {string} option - The selected commercial model option.
 */
  const onChangeCommercialModel = (option) => {
    let commercialModelValue =
      commericial_contractDurInfo.byIds[option]?.id === "opex"
        ? "Opex"
        : "Capex";
    if (commericial_contractDurInfo.byIds[option]?.id === "operating_lease") {
      commercialModelValue = commericial_contractDurInfo.byIds[option].id;
    }
    dispatch(
      setProp({
        slice: "dynamic",
        key: "reference_solution.commercial_model",
        value: commercialModelValue,
      })
    );

    dispatch(
      setProp({
        slice: "business",
        key: "view.PTDAC.commercial_model",
        value: option,
      })
    );
    dispatch(
      setProp({
        slice: "dynamic",
        key: "reference_solution.contract_duration",
        value: commericial_contractDurInfo.byIds[option]?.data?.min,
      })
    );
    dispatch(
      setProp({
        slice: "business",
        key: "view.PTDAC.contract_duration",
        value: commericial_contractDurInfo.byIds[option]?.data?.min,
      })
    );
    loadCommericalContractUIData(
      dynamicSelector.data.reference_solution.solution,
      commercialModelValue
    );
    return;
  };

  const onChangeDuration = (e?) => {
    const contractDurValue = e ? e.value : getDuration("min");
    dispatch(
      setProp({
        slice: "dynamic",
        key: "reference_solution.contract_duration",
        value: contractDurValue,
      })
    );

    dispatch(
      setProp({
        slice: "business",
        key: "view.PTDAC.contract_duration",
        value: contractDurValue,
      })
    );
  };

  const getDuration = (type) => {
    const __commercialModel = store.getState().dynamic.data.reference_solution.commercial_model;
    const commercialModel = convertCommercialModelToLower(__commercialModel);
    const contractDurInfo =
      commericial_contractDurInfo.byIds[commercialModel] &&
      commericial_contractDurInfo.byIds[commercialModel].data;

    let duration;
    if (contractDurInfo) {
      switch (type) {
        case "min":
          duration = contractDurInfo.min.toString();
          break;

        case "max":
          duration = contractDurInfo.max.toString();
          break;

        case "step":
          duration = contractDurInfo.stepBy.toString();
      }
    }
    return duration;
  };

  const convertCommercialModelToLower = (commercialDuration) => {
    return commercialDuration.toLocaleLowerCase();
  };

  return (
    <>
      {fullSolution !== "mining" && (
        <>
          <TextInputLabelContent>Solution Type *</TextInputLabelContent>
          <div
            className="ml mb mt"
            style={{
              display: "flex",
              width: "50%",
            }}
          >
            {referenceSolutionButtons.map((radio, i) => {
              return (
                <Fragment key={i}>
                  <RadioButton
                    key={i}
                    onChange={(e) => {
                      buttonSelect(radio);
                    }}
                    checked={
                      dynamicSelector.data.reference_solution.solution ===
                      radio.value
                    }
                  />
                  <RadioButtonLabelContent>
                    {radio.value}
                  </RadioButtonLabelContent>
                </Fragment>
              );
            })}
          </div>
        </>
      )}
      {commercialDuration && (
        <>
          <HorizontalDivider className="horizontalDivider" />
          <TextInputLabelContent>Commercial Model *</TextInputLabelContent>
          <div
            className="ml mb mt"
            style={{
              display: "flex",
              width: "80%",
            }}
          >
            {commericial_contractDurInfo.allIds.map((option, i) => {
              return (
                <Fragment key={i}>
                  <RadioButton
                    key={i}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onChangeCommercialModel(option);
                      }
                    }}
                    checked={
                      convertCommercialModelToLower(
                        dynamicSelector.data.reference_solution.commercial_model
                      ) === option
                    }
                  />
                  <RadioButtonLabelContent>
                    {commericial_contractDurInfo.byIds[option].value}
                  </RadioButtonLabelContent>
                </Fragment>
              );
            })}
          </div>
          <div id="contract-duration">
            <TextInputLabelContent>{`Contract Duration (${
              commericial_contractDurInfo.byIds[
                convertCommercialModelToLower(
                  dynamicSelector.data.reference_solution.commercial_model
                )
              ] &&
              commericial_contractDurInfo.byIds[
                convertCommercialModelToLower(
                  dynamicSelector.data.reference_solution.commercial_model
                )
              ].data.duration
            }) *`}</TextInputLabelContent>
            <div className="ml">
              <SliderComponent
                minDuration={getDuration("min")}
                maxDuration={getDuration("max")}
                stepBy={getDuration("step")}
                sliderStateValue={
                  dynamicSelector.data.reference_solution.contract_duration
                }
                handleChange={(e) => onChangeDuration(e)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(FullSolutionsView);
