
import { fullMiningType, fullSolType } from "Components/Content/configurationArea/Business/system";
import { encodeBase64 } from "Components/Logics/ensoCommonFunctions";
import {
  ROLE,
  STATUS,
  caseSubTypes,
  caseTypesMap,
  extensionOfferTools,
  mpwSegmentSolution,
  sCRMRegex,
  salesChannelLogic,
  toolRedirect,
} from "Components/Misc/CaseDetails_System";
import OOT from "Constants/services/oot";
import {
  changeAppState,
  resetAuth,
  setAccount,
  setError,
  showLoader,
  showModal,
  updateAccount,
} from "Store/general/auth";
import { totalCost } from "Store/contentState/cartBoqSlice";
import { resetCD, setData, setOBJ, setStatus } from "Store/contentState/caseDetails/caseDetailsSlice";
import { resetFC } from "Store/contentState/caseDetails/findCaseSlice";
import { RootState, store } from "Store/mainStore";
import { setLive, setVisible } from "Store/general/theme";
import { generateSyncOfferItems } from "Utils";
import _ from 'lodash';
import { getStateOfElement } from "./stateMachine/modules/CONFIG/configurator_statelogics";
/* LOGIC FOR SALES CHANNEL */

export const handleSalesChannelLogic = (state) => {
  //const state = store.getState();

  const selectedSalesChannel = state.caseDetails.data.sales_channel;

  if (selectedSalesChannel) {
    if (selectedSalesChannel === "DIR") {
      //  store.dispatch(setData({ key: 'endCustomer', value: state.caseDetails.data.account }))
      store.dispatch(
        setData({ key: "endCustomer", value: state.caseDetails.data.endCustomer })
      );
      store.dispatch(setData({ key: "distributor", value: "" }));
      store.dispatch(setData({ key: "resellers", value: "" }));
      store.dispatch(setData({ key: "indirect_resellers", value: "" }));
    }
    if (selectedSalesChannel === "DIS") {
      store.dispatch(
        setData({
          key: "endCustomer",
          value: state.caseDetails.data.endCustomer,
        })
      );
      store.dispatch(
        setData({ key: "distributor", value: state.caseDetails.data.account })
      );
      store.dispatch(setData({ key: "resellers", value: "" }));
      store.dispatch(
        setData({
          key: "indirect_resellers",
          value: state.caseDetails.data.indirect_resellers,
        })
      );
    }
    if (selectedSalesChannel === "RES") {
      store.dispatch(
        setData({
          key: "endCustomer",
          value: state.caseDetails.data.endCustomer,
        })
      );
      store.dispatch(setData({ key: "distributor", value: "" }));
      store.dispatch(
        setData({ key: "resellers", value: state.caseDetails.data.account })
      );
      store.dispatch(setData({ key: "indirect_resellers", value: "" }));
    }
    switch (state.caseDetails.data.sales_channel) {
      case selectedSalesChannel:
        store.dispatch(
          setVisible({
            id: salesChannelLogic[selectedSalesChannel].visible,
            value: true,
          })
        ); //stopped due to max stack error
        store.dispatch(
          setVisible({
            id: salesChannelLogic[selectedSalesChannel].hidden,
            value: false,
          })
        );
        break;
      default:
        break;
    }
    handleCaseDepSaleChannelLogic(state); //Makes fields R / O on the basis of casetype
  }
};

export const handleRestLogic = () => {
  store.dispatch(resetAuth());
  store.dispatch(resetFC());
  store.dispatch(resetCD());
};

export const handleAccountLogic = (oot_response, state) => {
  if (!oot_response.data.sales_channel.default) {
    return;
  }

  switch (oot_response.data.sales_channel.default) {
    case "DIR":
      store.dispatch(
        updateAccount({
          id: oot_response.data.end_customer.nid,
          value: oot_response.data.end_customer.name,
          account_id: oot_response.data.end_customer.account_id,
          type: "account",
        })
      );

      store.dispatch(
        updateAccount({
          id: oot_response.data.end_customer.nid,
          value: oot_response.data.end_customer.name,

          type: "end_customer",
        })
      );

      //set-the-fields
      store.dispatch(
        setOBJ({ path: "account", value: oot_response.data.end_customer.nid })
      );
      store.dispatch(
        setOBJ({
          path: "endCustomer",
          value: oot_response.data.end_customer.nid,
        })
      );

      store.dispatch(
        setOBJ({
          path: "endCustomer_account_nid",
          value: oot_response.data.end_customer.account_nid,
        })
      );
      break;
    case "RES":
      // alert('RES')
      store.dispatch(
        updateAccount({
          id: oot_response.data.reseller.nid,
          value: oot_response.data.reseller.name,
          account_id: oot_response.data.end_customer.account_id,
          type: "account",
        })
      );
      store.dispatch(
        updateAccount({
          id: oot_response.data.end_customer.nid,
          value: oot_response.data.end_customer.name,

          type: "end_customer",
        })
      );
      store.dispatch(
        updateAccount({
          id: oot_response.data.reseller.nid,
          value: oot_response.data.reseller.name,
          type: "resellers",
        })
      );

      store.dispatch(
        setOBJ({ path: "account", value: oot_response.data.reseller.nid })
      );
      store.dispatch(
        setOBJ({
          path: "endCustomer",
          value: oot_response.data.end_customer.nid,
        })
      );
      store.dispatch(
        setOBJ({
          path: "endCustomer_account_nid",
          value: oot_response.data.end_customer.account_nid,
        })
      );
      store.dispatch(
        setOBJ({ path: "resellers", value: oot_response.data.reseller.nid })
      );

      //set-the-fields

      break;
    case "DIS":
      store.dispatch(
        updateAccount({
          id: oot_response.data.distributor.nid,
          value: oot_response.data.distributor.name,
          account_id: oot_response.data.end_customer.account_id,
          type: "account",
        })
      );
      //   id: oot_response.data.distributor.nid,
      //   value: oot_response.data.distributor.name,
      //   type: "account",
      // },'result=>',store.getState().authSlice.system.account)
      store.dispatch(
        updateAccount({
          id: oot_response.data.distributor.nid,
          value: oot_response.data.distributor.name,
          type: "distributor",
        })
      );
      store.dispatch(
        updateAccount({
          id: oot_response.data.end_customer.nid,
          value: oot_response.data.end_customer.name,

          type: "end_customer",
        })
      );
      store.dispatch(
        setOBJ({
          path: "endCustomer_account_nid",
          value: oot_response.data.end_customer.account_nid,
        })
      );
      store.dispatch(
        setOBJ({
          path: "endCustomer_account_nid",
          value: oot_response.data.end_customer.account_nid,
        })
      );
      store.dispatch(
        updateAccount({
          id: oot_response.data.indirect_reseller.nid,
          value: oot_response.data.indirect_reseller.name,
          type: "indirect_resellers",
        })
      );
      //set-the-fields
      store.dispatch(
        setOBJ({ path: "account", value: oot_response.data.distributor.nid })
      );
      store.dispatch(
        setOBJ({
          path: "distributor",
          value: oot_response.data.distributor.nid,
        })
      );
      store.dispatch(
        setOBJ({
          path: "endCustomer",
          value: oot_response.data.end_customer.nid,
        })
      );
      store.dispatch(
        setOBJ({
          path: "indirect_resellers",
          value: oot_response.data.indirect_reseller.nid,
        })
      );
      break;
    default:
      break;
  }
};

export const handleExceptionR6InternalLogic = (state) => {
  if (state.findCase.data.caseType === Object.keys(caseTypesMap)[3] && state.authSlice.system.account.length > 0) {
    store.dispatch(setData({ key: "sales_channel", value: "DIR" }));
    store.dispatch(
      setAccount(
        state.authSlice.system.account.filter((e) => e?.value?.toLowerCase()?.includes("nokia"))
      )
    );
    return true; //if this logic is executing
  }
};

export const handleCaseDepSaleChannelLogic = (state) => {
  if (state.findCase.data.caseType === "frame_agreement") {
    var found = state.authSlice.system.currentState;
    var copyFound = Object.assign({}, found);
    let salesChannel = state.caseDetails.data.sales_channel;

    switch (salesChannel) {
      case "DIR":
        copyFound = {
          ...copyFound,
          fieldsRequired: { ...copyFound.fieldsRequired, endCustomer: true },
        };
        copyFound.fieldsRequired.distributor = false;
        copyFound.fieldsRequired.resellers = false;
        break;
      case "DIS":
        copyFound = {
          ...copyFound,
          fieldsRequired: { ...copyFound.fieldsRequired, endCustomer: false },
        };
        copyFound.fieldsRequired.distributor = true;
        copyFound.fieldsRequired.resellers = false;
        break;
      case "RES":
        copyFound = {
          ...copyFound,
          fieldsRequired: { ...copyFound.fieldsRequired, endCustomer: false },
        };
        copyFound.fieldsRequired.distributor = false;
        copyFound.fieldsRequired.resellers = true;
        break;
      default:
    }
    store.dispatch(changeAppState(copyFound));
  }
};

export const generateEOTRedirect = (redirect_uri) => {
  //step 1
  const dbName = toolRedirect.ENSO.dbName;
  const redirectURi = redirect_uri; //redirect.homePage;
  //step 2
  const encodedParams = encodeBase64(`${dbName}&redirect_uri=${redirectURi}`);
  //step 3
  const loginMSALAuth = `https://login.microsoftonline.com/${toolRedirect.ENSO.tenantID}/oauth2/v2.0/authorize?scope=User.Read&response_type=code&client_id=${toolRedirect.ENSO.clientID}&redirect_uri=${toolRedirect.ENSO.domain}/auth_oauth/microsoft&state=${encodedParams}`;
  return loginMSALAuth;
};
export const handleRedirectType = (state: RootState) => {
  // const authSelector = state.authSlice;
  const caseDetailsSelector = state.caseDetails
  const findCaseSelector = state.findCase
  const toolSelection = state.toolSelection
  // const cartBoqSelector = state.cartBoq
  const businessSelector = state.business;

  try {
    var caseType = findCaseSelector.data.caseType;
    var subType = findCaseSelector.data.subType;
    var customerSegment = businessSelector.data.options.PTDAC.caseIndustry;
    var advanceFeatures = businessSelector.data.view.PTDAC.additionalFeature;
    if (Object.keys(caseSubTypes).includes(caseType)) {
      var caseTypeSubTypeKeysArray = caseSubTypes[caseType].map(
        (obj, caseIndex) => ({
          caseType: obj.types
            .map(
              (typeObj, index) =>
                typeObj.key === subType && { subType: typeObj.key, id: index }
            )
            .filter((z) => z !== false),
          caseIndex: caseIndex,
        })
      );
      var caseTypeSubTypeKeys = caseTypeSubTypeKeysArray
        .flatMap((z) => z)
        .filter((z) => z.caseType.length !== 0)[0];
      if (caseTypeSubTypeKeys) {
        //get the redirect type by reaching the node in caseSubTypes in system;
        var redirect = caseTypeSubTypeKeys
          ? caseSubTypes[caseType][caseTypeSubTypeKeys.caseIndex].redirect
          : null;
        if (subType === "help") {
          //If Redirect is not defined, user selects:
          if (mpwSegmentSolution.includes(customerSegment)) {
            //If Solution is "MPW-S", system sets Redirect = EnSo shop
            redirect = toolRedirect.ENSO;
          } else {
            //Solution is undefined:
            redirect = toolRedirect.OOT;
            if (advanceFeatures.length > 0) {
              //If any Advance features are needed, system sets Redirect = EnSo shop, otherwise, system sets Redirect = OOT
              redirect = toolRedirect.ENSO;
            }
          }
        }

        if (state.dynamic.data.reference_solution.solution === "MPW") {
          redirect = redirect = toolRedirect.OOT;
          return redirect
        }

        if (redirect === null) {
          //If Redirect is not defined, user selects:

          if (toolSelection.data.extensionTool === extensionOfferTools.NDAC) {
            //"Packaged Plug & Play as-a-service solution (NDAC)".(Renamed to - NDAC Compact) System sets Redirect = OOT.
            redirect = redirect = toolRedirect.OOT;
          }
          if (
            toolSelection.data.extensionTool === extensionOfferTools.MPW_GSM
          ) {
            //"Packaged Plug & Play as-a-service solution (NDAC)".(Renamed to - NDAC Compact) System sets Redirect = OOT.
            redirect = redirect = toolRedirect.ENSO;
          }
        }
        return redirect;
      }
      if (caseTypeSubTypeKeys === undefined) {
        //handle no-subtype scenario - DEMAN/FRAME-agremments
        redirect = caseSubTypes[caseType][0].redirect;
        return redirect;
      }
    } else {
      alert('Error Creating:Looks like Casetype & subtype is not selected ')
    }
  } catch (error) {
    store.dispatch(
      setError({
        key: "error",
        value: { errorMsg: error, errorCode: null },
      })
    );
  } finally {

  }
};

const getBoqForContainers = (data, dynamicList, boq_list, key, state, getListOfFields) => {
  const containerFields = { ...data[1]['field_details'] };
  Object.entries(containerFields).map((data, _index) => {
    const perfield = dynamicList[key][data[1]['id']];
    data[1]['type'] === 'composite' ? getCompositeBoq(data, boq_list, state) : getBoqList(perfield, data, boq_list, state, getListOfFields);
  });
}

const getBoqForSubsteps = (data, dynamicList, boq_list, key, state, getListOfFields) => {
  const containerFields = { ...data[1]['field_details'] };
  Object.entries(containerFields).map((data, _index) => {
    if (data[1]['type'] === 'container') {
      getBoqForContainers(data, dynamicList, boq_list, key, state, getListOfFields);
    } else {
      //  if fields are outside of containers itself
      const perfield = dynamicList[key][data[1]['id']];
      getBoqList(perfield, data, boq_list, state, getListOfFields);
    }
  });
}

export const getBoqOptions = (state, getListOfFields) => {
  let dynamicList = state.dynamic.data.element
  let boq_list = {};
  const packagesList = state.config_stepper.data.solution_form_data.packages;

  if (packagesList && !_.isEmpty(dynamicList)) {
    Object.keys(packagesList).forEach(function (key) {
      const fieldDetails = packagesList[key].field_details;
      Object.entries(fieldDetails).map((data, _index) => {
        //  if fields are inside a container
        if (data[1]['type'] === 'container') {
          getBoqForContainers(data, dynamicList, boq_list, key, state, getListOfFields);
        } else if (data[1]['type'] === 'details') {
          // if the fileds are inside the substeps
          getBoqForSubsteps(data, dynamicList, boq_list, key, state, getListOfFields);
        }
        else {
          //  if fields are outside itself
          const perfield = dynamicList[key][data[1]['id']];
          getBoqList(perfield, data, boq_list, state, getListOfFields);
        }
      })
    })
  }
  return boq_list
}

const getVisibilityOfElement = (data, state, getListOfFields) => {
  const allfieldData = getListOfFields(state.dynamic.data.element);
  const allStates = getStateOfElement(data[1], allfieldData);
  return allStates;
}

const getModalData = (obj, key) => {
  if (_.has(obj, key)) {
    return _.get(obj, key);
  }
  return _.reduce(obj, (result, value) => {
    if (result) return result; // If found, return immediately
    if (_.isObject(value)) {
      return getModalData(value, key);
    }
    return result;
  }, null);
}

const getCompositeBoq = (data, boq_list, state) => {
  let compositeKey = data[1]['form_callback']['form_identifier'];
  compositeKey = compositeKey.replace(/-$/, '');
  const modalData = getModalData(state.dynamic.data.modal, compositeKey);
  // state.dynamic.data.modal[state.config_stepper.data.activeStep][state.config_stepper.data.activeSubStep][compositeKey];
  _.map(modalData, (eachData, key) => {
    _.map(eachData, (perfield, attr) => {
      // boq_list[data[1]['label']] = attr;
      const jkey = data[1]['label'] + '-' + key;
      if (!boq_list[jkey]) {
        boq_list[jkey] = {};
      }
      boq_list[jkey][attr] = perfield.value !== undefined ? perfield.value : perfield;
    });
  });
}

const getBoqList = (perfield, data, boq_list, state, getListOfFields) => {
  const visibileValue = data[1]['type'] === 'hidden' ? 'none' : getVisibilityOfElement(data, state, getListOfFields);
  if (perfield !== '' && visibileValue['visibility'] === 'block') {
    if (data[1]['type'] === 'checkbox' && data[1]['multiple']) {
      if (_.some(perfield, 'value')) {
        boq_list[data[1]['label']] = perfield.map((item) => item.value);
      } else {
        boq_list[data[1]['label']] = _.flatMap(perfield, array =>
          _.chain(array)
            .filter({ checked: true })
            .map(obj => _.pick(obj, ['product_code', 'quantity']))
            .value()
        );
      }
    } else if (data[1]['type'] === 'checkbox') {
      boq_list[data[1]['label']] = (typeof (perfield) !== 'object') ? perfield.map((item) => item.value) : (perfield).value;
    } else if (data[1]['type'] === 'select' && data[1]['multiple']) {
      boq_list[data[1]['label']] = _.map(perfield, 'value');
    } else if (data[1]['type'] === 'radio' && typeof (perfield) === 'object') {
      if (_.some(perfield, 'value')) {
        boq_list[data[1]['label']] = perfield.map((item) => item.value);
      } else if (perfield.value) {
        boq_list[data[1]['label']] = perfield.value;
      } else {
        boq_list[data[1]['label']] = _.flatMap(perfield, array =>
          _.chain(array)
            .filter({ checked: true })
            .map(obj => _.pick(obj, ['product_code', 'quantity']))
            .value()
        );
      }
    } else {
      if (data[1]['type'] === 'markup') {
        // as we have markup as notes for that check if label is there else make it blank
        //  as markup is for MXIE also it will conflict here
        if(perfield && _.isArray(perfield)) {
          boq_list[data[1]['label']] = perfield.map(item =>
            item.label ? { label: item.label, value: item.value } : ''
          );
        }
      } else {
        boq_list[data[1]['label']] = perfield;
        if (perfield && _.has(perfield, 'value')) {
          boq_list[data[1]['label']] = perfield.value !== undefined ? perfield.value : null;
        }
      }
    }
  }
}

export const handleOffer = async (state: RootState, getListOfFields?) => {
  const authSelector = state.authSlice;
  const caseDetailsSelector = state.caseDetails
  const findCaseSelector = state.findCase
  // const toolSelection = state.toolSelection
  const businessSelector = state.business
  // const applicationsSelector = state.applications
  // const connectivitySelector = state.connectivity
  // const edgeSelector = state.edge;
  
  // const stateMachineSelector = state.stateMachine
  // const devicesSelector = state.devices;
  // const warrantySelector = state.warranty;

  const cartBoqSelector = state.cartBoq
  // Taking the present state of the cart
  // const cartBoqSelector = state.undoCartBoq.present;

  const cartTotal = totalCost(state.cartBoq);
  const configSelector = state.config_stepper;
  const dynamicSelector = state.dynamic;
  
  const solutionCategory = configSelector.data.solutionCategory;
  const solutionType = configSelector.data.solutionType;

  try {
    let redirect = handleRedirectType(state);
    let contractDuration;
    let protoType = {};
    let config_mpw_params = {};
    let mpw_additional_info = {};

    let configurator_mpw_list = {}
    let mpwItems = [];
    let cartItems = cartBoqSelector.data.cart.items;

    if (businessSelector.data.options.PTDAC.caseIndustry === "mining" && businessSelector.data.options.PTDAC.ndac_ref.fullSolType === "mpw") {
      const changedRedirect = identifySolutionRedirect([businessSelector.data.options.PTDAC.ndac_ref.fullSolType, businessSelector.data.options.PTDAC.ndac_ref.fullMiningType]).redirect

      if (changedRedirect) {
        redirect = changedRedirect
      }
    }
    // redirect to MPW when caseindustry is manufacturing/logistics and radio selected is MPW
    else if (_.includes(["manufacturing","logistics"],businessSelector.data.options.PTDAC.caseIndustry) && configSelector.data.solutionCategory === 'MPW') {
      const changedRedirect = identifyMPWRedirect([configSelector.data.solutionCategory]).redirect
      if (changedRedirect) {
        redirect = changedRedirect
      }
    }
    else {
      if ((businessSelector.data.options.PTDAC.caseIndustry === "port" && configSelector.data.solutionCategory === 'MPW')) {
        const changedRedirect = identifyMPWRedirect([configSelector.data.solutionCategory]).redirect
        if (changedRedirect) {
          redirect = changedRedirect
        }
      }

      if (_.includes(['reference_solution','mining','manufacturing','port'], businessSelector.data.options.PTDAC.caseIndustry)) {
        // && configSelector.data.solution_selected === 'cmu'
        const datesData = getDateCalculated();
        config_mpw_params = {
          "segment": configSelector.data.solution_selected === 'cmu' ? "ug" : "",
          "estimated_order_date": datesData.final_est_order_date,
          "requested_deli_date": datesData.final_req_delivery_date,
          "nature_of_order": "Sales order",
          "network_details": caseDetailsSelector.data.summary && caseDetailsSelector.data.summary !== "" ? caseDetailsSelector.data.summary : "Offer",
        };
        
        if (solutionCategory === 'MPW' && solutionType !== 'Standalone') {
          const allboqItems = _.cloneDeep(state.dynamic.data.boq);
          // update the type from UI cart boq into original boq , as we need that for differenciating mpw and ndac items
          _.map(allboqItems,(eachItem) => {
            if(eachItem.type === "") {
              const saleItemFound = _.filter(cartBoqSelector.data.cart.items,(uiboqItem) => uiboqItem.product_id === eachItem.productCode);
              if(saleItemFound && saleItemFound.length > 0) {
                eachItem['type'] = saleItemFound[0].type;
              }
            }
          });
          // when solution is mpw we want to divide the cart boq based on type
          mpwItems = _.filter(allboqItems, (e) => {
              const prodId = e.productCode || e.si_code;
              const prodType = e.type || "";
              const isMPWType = _.includes(['hardware','software','end_end_services','product_attached_services','care','srs'], (prodType).toLowerCase());
              const isPSND = _.includes(prodId, 'PSND');
              const isEmptyType = prodType === "" ? true : false;
          
              return ((isMPWType && !isPSND) || isEmptyType );
          });
          // all items other than mpw items add in boq for ndac items
          cartItems = _.filter(allboqItems, (obj1) => {
            const hasSiCode = obj1.hasOwnProperty('si_code');
            const hasMatchingProductCode = mpwItems.some(obj2 => obj1.hasOwnProperty('productCode') && obj1.productCode !== obj2.productCode);
        
            return !hasSiCode && hasMatchingProductCode;
          });
        }
        configurator_mpw_list = {
          items: (configSelector.data.solution_selected === 'cmu' ? state.dynamic.data.boq : (mpwItems.length > 0 ? mpwItems : [])),
          partner_accredits: (dynamicSelector.data.boq && dynamicSelector.data.boq[0] && dynamicSelector.data.boq[0].partner_accredits) ? dynamicSelector.data.boq[0].partner_accredits : "",
          dod_applicable: (dynamicSelector.data.boq && dynamicSelector.data.boq[0] && dynamicSelector.data.boq[0].dod_applicable) ? dynamicSelector.data.boq[0].dod_applicable : "",
          incoterm: "",
          boq_options: getBoqOptions(state, getListOfFields) // this has been complicated , we can make another dataset with values when add to cart and use it here
        }
      }
      if ((_.includes(['reference_solution','mining','manufacturing','port'], businessSelector.data.options.PTDAC.caseIndustry) && configSelector.data.solution_selected !== 'cmu' && redirect.id != "ENSO")) {
        const slaWarrantyProds = ndac_fetchSlaWarrantyProducts(cartBoqSelector,dynamicSelector,businessSelector);
        contractDuration = dynamicSelector.data.reference_solution.contract_duration;
        protoType = {
          "services": generateSyncOfferItems('services', cartBoqSelector.data.cart.items.filter((e) => e.type === "end_end_services" || (e?.cui_type === "service")), contractDuration),
          "boq": generateSyncOfferItems('ndac', cartItems, undefined),
          "type": 'ndac',
          "total_prod_cost": cartTotal,
          "ndac_premium_addons": slaWarrantyProds
        }
      }
    }

    //nw_plan_id - odt planner id if available
    let offerList = {
      id: "",
      name: caseDetailsSelector.data.caseName,
      shipping_country: caseDetailsSelector.data.shipping_country,
      sales_channel: caseDetailsSelector.data.sales_channel,
      end_customer: caseDetailsSelector.data.endCustomer,
      market: caseDetailsSelector.data.market,
      summary: caseDetailsSelector.data.summary,
      owner: authSelector.login.mail,
      network_location: caseDetailsSelector.data.country,
      co_owner: caseDetailsSelector.data.co_owner,
      scrm_id: !sCRMRegex.test(findCaseSelector.data.sCRMID)
        ? "19.XY.123456"
        : findCaseSelector.data.sCRMID,
      public_funding_required: caseDetailsSelector.data
        .public_funding_required
        ? "yes"
        : "no",
      reason_for_funding: caseDetailsSelector.data.public_funding_required
        ? caseDetailsSelector.data.summary
        : "NA",
      case_type: findCaseSelector.data.caseType,
      case_sub_type: findCaseSelector.data.subType,
      customerSegmentId: businessSelector.data.options.PTDAC.caseIndustry,
      budgetary: caseDetailsSelector.data.caseCategory,
      lead_product: "NDAC",
      commercial_model: findCaseSelector.data.subType === "help" ? businessSelector.data.view.PTDAC.commercial_model.toLowerCase() : caseDetailsSelector.data.commercial_model.toLowerCase(),
      contract_duration: contractDuration ? contractDuration : caseDetailsSelector.data.contract_duration,
      contracting_party_id: caseDetailsSelector.data.account,
      currency: caseDetailsSelector.data.currency,
      network_id: caseDetailsSelector.data.network_id,
      solution: configSelector.data.solution_selected,
      requested_state: 'draft',
      validationDetails: dynamicSelector.data.validationDetails ? dynamicSelector.data.validationDetails['validateResponse'] : {},
      nw_plan_id : dynamicSelector.data.reference_solution ? dynamicSelector.data.reference_solution.linkedPlanner['sys_id'] : ''
    }

    setStateOffer(offerList, configurator_mpw_list, protoType);

    offerList = setLeadProduct(config_mpw_params, configSelector, offerList);

    // decide what data to be sent to sync-offer
    // const dataProtoType = businessSelector.data.options.PTDAC.caseIndustry === "port" && redirect.id != "ENSO" ? {
    //   offer: offerList,
    //   DAC_DATA: {
    //     ...protoType
    //   }
    // } : (
      // if soln is reference soln 
      const dataProtoType = (_.includes(['NDAC', 'MPW'],solutionCategory) && solutionType !== 'Standalone') ?
        {
          offer: offerList,
          configurator_mpw: {
            ...configurator_mpw_list
          },
          DAC_DATA: {
            ...protoType
          },
          eot_services_response: { ...dynamicSelector.data.eot_response_syncoffer }
        } :
        {
          offer: offerList,
          configurator_mpw: {
            ...configurator_mpw_list
          }
        }
    // );
    if (findCaseSelector.data.subType === "help") {
      if (!businessSelector.data.options.PTDAC.caseIndustry) {
        store.dispatch(
          setError({
            key: "error",
            value: {
              errorCode: null,
              errorMsg: "Please select a customer segment",
            },
          })
        );

        return { response_code: 0, response_msg: 'Please select a customer segment' };
      }
    }

    if (redirect.hasOwnProperty('id')) {
      switch (redirect.id) {
        case "OOT":
          store.dispatch(showLoader(true));
          store.dispatch(showModal({ msg: "Offer creation in progress, Please wait..", title: "Creating Offer", show: true, variant: "WARNING" }))
          if (!authSelector.login.hasOwnProperty("accessToken")) {

            setError({
              key: "warn",
              value: {
                errorCode: null,
                errorMsg: "The accesstoken is not set,re-login is required.",
              },
            });
            return;
          }

          try {
            const response = await OOT.syncOffer({
              data: dataProtoType,
            })
            store.dispatch(showLoader(false));
            if (response.status) {
              store.dispatch(setLive(true));
              store.dispatch(showModal({ msg: `Offer: ${response.data.offer_id} created`, title: `Offer ${response.data.offer_id} successfully created.`, show: true }))
              await new Promise(resolve => setTimeout(resolve, 3000));
              window.open(
                redirect.urlPrefix + response.data.offer_id + "&cui_login=ms", "_top"
              );
              
              //return { response_code: 1, response_msg: response.data }
              //  setTimeout(()=>handleRestLogic(),4000)

            } else {
              store.dispatch(showModal({ msg: response.errorMessage, title: "Something went wrong", show: true, variant: "ERROR" }))
              return { response_code: 0, response_msg: response.errorMessage }
            }
          } catch (err) {
            store.dispatch(showModal({ msg: err.response.status + ':' + err.message, title: "Server Error", show: true, variant: "ERROR" }))
            store.dispatch(setLive(false));
          }

          break;
        case "ENSO":
          var endCust = await OOT.getAccountCustomer({ account_nid: caseDetailsSelector.data.endCustomer_account_nid });
          if (!endCust?.status) {
            store.dispatch(
              setError({
                key: "error",
                value: {
                  errorCode: null,
                  errorMsg: `Failed to  get Customers for ${caseDetailsSelector.data.account}`,
                },
              })
            );
            return { response_code: 0, response_msg: 'Failed to find three_star_acc_id name' };
          }
          if (endCust?.data?.accounts?.length == 0) {
            store.dispatch(
              setError({
                key: "error",
                value: {
                  errorCode: null,
                  errorMsg: `0 Customers found for the selected account, try again.`,
                },
              })
            );
            return { response_code: 0, response_msg: 'Failed to find three_star_acc_id name' };
          }
          //let three_star_acc = endCust?.data?.customers.find((c) => c.name == authSelector.system.account[0].value)
          let three_star_acc_id = endCust?.data?.accounts[0]['3*_number'];
          if (!three_star_acc_id) {
            store.dispatch(
              setError({
                key: "error",
                value: {
                  errorCode: null,
                  errorMsg: `Exception: 3 star id for account is empty, try again.`,
                },
              })
            );
            return { response_code: 0, response_msg: 'Failed to find three_star_acc_id name' };
          }
          store.dispatch(showLoader(false));
          // dispatch(setError(null));
          if (true) {
            if (!sCRMRegex.test(findCaseSelector.data.sCRMID)) {
              store.dispatch(
                setError({
                  key: "warn",
                  value: {
                    errorCode: null,
                    errorMsg: "Empty or invalid sCRM will not work for MPW-S cases",
                  },
                })
              );
              return;
            }
            // if (caseDetailsSelector.data.caseCategory === "Budgetary") {
            //   //const loginMSALAuth =`https://login.microsoftonline.com/${toolRedirect.ENSO.tenantID}/ oauth2/v2.0/authorize?scope=User.Read&response_type=code&client_id=${proces}&redirect_uri=https://iesp.cloud-test.nokia.com/auth_oauth/Microsoft&state=`

            //   if (authSelector.role === ROLE.INTERNAL) {
            //     window.open(generateEOTRedirect(redirect.homePage), "_self");
            //   }
            //   if (authSelector.role === ROLE.PARTNER) {
            //     window.open(
            //       generateEOTRedirect(redirect.homePagePartner),
            //       "_self"
            //     );
            //   }
            //   handleRestLogic();
            //   return { response_code: 1, response_msg: 'Redirected to EOT' };
            // }

            let decodedEndCustomer = undefined;
            if (
              caseDetailsSelector.data.sales_channel === "DIR" &&
              authSelector.OOT?.data?.end_customer?.name
            ) {
              decodedEndCustomer = authSelector.OOT.data.end_customer.name;
            } else {
              const search = authSelector.system.endCustomer.find(
                (z) => z.id === caseDetailsSelector.data.endCustomer
              );
              decodedEndCustomer = search.value;
            }
            // new implementation - change endcustomer to account: 21-11-23
            const searchAccount = authSelector.system.account.find(
              (z) => z.id === caseDetailsSelector.data.account
            );
            const decodedEndAccount = searchAccount.value;
            const endCustomerData = authSelector.system.endCustomer.find((e) => e.id === caseDetailsSelector.data.endCustomer)
            if (decodedEndAccount === undefined) {
              store.dispatch(
                setError({
                  key: "error",
                  value: {
                    errorCode: null,
                    errorMsg: "Failed to find account name",
                  },
                })
              );
              return { response_code: 0, response_msg: 'Failed to find account name' };
            }
            const base64Customer = encodeBase64(decodedEndCustomer);
            const base64Account = encodeBase64(decodedEndAccount);
            const scrmid = (findCaseSelector.data.sCRMID).toUpperCase();
            if (authSelector.role === ROLE.INTERNAL) {
              const urlParms = `?case_type=${findCaseSelector.data.caseType}&case_sub_type=${findCaseSelector.data.subType}&opportunity_id=${scrmid}&segment_id=${businessSelector.data.options.PTDAC.caseIndustry === 'reference_solution' ? 'ndac_reference_solution' : businessSelector.data.options.PTDAC.caseIndustry}&3_star_number=${three_star_acc_id}`;
              //  window.location.href = redirect.urlPrefix + urlParms;
              const encodedState = encodeBase64(urlParms);
              const url = redirect.urlPrefix + "?state=" + encodedState;
              window.open(generateEOTRedirect(url), "_self");
              handleRestLogic();
              return { response_code: 1, response_msg: 'Redirected to EOT' };
            }
            if (authSelector.role === ROLE.PARTNER) {
              const urlParms = `?case_type=${findCaseSelector.data.caseType}&case_sub_type=${findCaseSelector.data.subType}&opportunity_id=${scrmid}&segment_id=${businessSelector.data.options.PTDAC.caseIndustry}`;
              //  window.location.href = redirect.urlPrefixPartner + urlParms;
              const encodedState = encodeBase64(urlParms);
              const url = redirect.urlPrefixPartner + "?state=" + encodedState;
              window.open(generateEOTRedirect(url), "_self");
              handleRestLogic();
              return { response_code: 1, response_msg: 'Redirected to EOT' };
            }
          }
          break;
        default:
          break;
      }
    }
  } catch (error) {

    store.dispatch(setStatus(STATUS.ERROR));
    setError({
      key: "error",
      value: { errorMsg: error, errorCode: null },
    });
  }
};

const setStateOffer = (offerList, configurator_mpw_list, protoType) => {
  // set requested_state to approved when only mpw items are there and there are no ndac items
  if ((configurator_mpw_list['items'] && configurator_mpw_list['items'].length > 0) && (_.isEmpty(protoType) || (protoType['boq']).length === 0)) {
    offerList.requested_state = 'approved';
  }
}

const setLeadProduct = (config_mpw_params, configSelector, offerList) => {
  if (Object.keys(config_mpw_params).length > 0) {
    // for both CMU and MPW
    if (_.includes(['MPW'], configSelector.data.solutionCategory)) {
      offerList['lead_product'] = 'MPW';
    }
    offerList = { ...offerList, ...config_mpw_params };
  }
  return offerList;
}

const getDateCalculated = () => {
  // estimated date will be current date + 2 months
  let current_est_date = new Date();
  current_est_date.setMonth(current_est_date.getMonth() + 2);
  const est_order_date = current_est_date.getMonth() + 1;
  const estyear = current_est_date.getFullYear();
  const estdate = current_est_date.getDate();

  // requested date will be current date + 6 months
  let current_req_date = new Date();
  current_req_date.setMonth(current_req_date.getMonth() + 6);
  const req_delivery_date = current_req_date.getMonth() + 1;
  const reqyear = current_req_date.getFullYear();
  const reqdate = current_req_date.getDate();

  const est_order_month = est_order_date < 10 ? '0' + est_order_date : '' + est_order_date;
  const req_delivery_month = req_delivery_date < 10 ? '0' + req_delivery_date : '' + req_delivery_date;

  const finalestdate = estdate < 10 ? '0' + estdate : '' + estdate;
  const finalreqdate = reqdate < 10 ? '0' + reqdate : '' + reqdate;

  const est = `${estyear}-${est_order_month}-${finalestdate}`;
  const req_delivery = `${reqyear}-${req_delivery_month}-${finalreqdate}`;

  return { 'final_est_order_date': est, 'final_req_delivery_date': req_delivery };
}

export const fetchAccountData = async (state: RootState) => {
  const caseDetailsSelector = state.caseDetails
  var endCust = await OOT.getAccountCustomer({ account_nid: caseDetailsSelector.data.endCustomer_account_nid });
  if (!endCust?.status) {
    store.dispatch(
      setError({
        key: "error",
        value: {
          errorCode: null,
          errorMsg: `Failed to  get Customers for ${caseDetailsSelector.data.account}`,
        },
      })
    );
    return { response_code: 0, response_msg: 'Failed to find three_star_acc_id name' };
  }
  if (endCust?.data?.accounts?.length == 0) {
    store.dispatch(
      setError({
        key: "error",
        value: {
          errorCode: null,
          errorMsg: `0 Customers found for the selected account, try again.`,
        },
      })
    );
    return { response_code: 0, response_msg: 'Failed to find three_star_acc_id name' };
  }
  let three_star_acc_id = endCust?.data?.accounts[0]['3*_number'];
  if (!three_star_acc_id) {
    store.dispatch(
      setError({
        key: "error",
        value: {
          errorCode: null,
          errorMsg: `Exception: 3 star id for account is empty, try again.`,
        },
      })
    );
    return { response_code: 0, response_msg: 'Failed to find three_star_acc_id name' };
  } else {
    const accountName = endCust?.data?.accounts[0]['name'];
    store.dispatch(setOBJ({ path: "account_name", value: accountName }));
    store.dispatch(setOBJ({ path: "three_star_number", value: three_star_acc_id }));
  }
}


export const fetchSlaWarrantyProducts = (warrantySelector, stateMachineSelector,businessSelector) => {
  const planselected = warrantySelector?.data?.options?.PTDAC?.subscription_plan;
  const slawarrantyData = stateMachineSelector?.PTDAC?.selected?.warranty;
  if (!slawarrantyData) {
    return
  }
  let warrantyProds = [];
  if (slawarrantyData.extendDurationWarrantyTab?.data && warrantySelector?.data?.options?.PTDAC?.warranty[1]) {
    warrantyProds = slawarrantyData?.extendDurationWarrantyTab?.data?.products;
  }
  let slaProds = [];
  if (slawarrantyData?.plansWarrantyTab?.data?.plans?.byPlan[planselected]) {
    slaProds = slawarrantyData?.plansWarrantyTab?.data?.plans?.byPlan[planselected]?.products;
  }
  const slawarrantyProds = warrantyProds.concat(slaProds);
  const addonArray = [];
  slawarrantyProds.forEach(eachProd => {
    if(eachProd) {
      const prodObj = {
        "product_code": eachProd.product_id,
        "quantity": eachProd.quantity,
        "duration": eachProd.type === 'service' ? warrantySelector.data.options.PTDAC.subscription_duration : "NA",
        "type": eachProd.type === 'service' ? 'support' : eachProd.type
      }
      if(businessSelector.data.view.PTDAC.commercial_model === "capex") {
        prodObj["extended_years"] = eachProd.type === 'warranty' ? warrantySelector.data.options.PTDAC.warranty[1] : "NA";
      } else {
        prodObj["extended_month"] = eachProd.type === 'warranty' ? warrantySelector.data.options.PTDAC.warranty[1] * 12 : "NA";
      }
      addonArray.push(prodObj);
    }
  });
  return addonArray;
}

export const ndac_fetchSlaWarrantyProducts = (cartBoqSelector,dynamicSelector,businessSelector) => {
  // const planselected = dynamicSelector?.data?.element?.sla_warranty?.available_subscription_plans.value;
  // const slawarrantyData = stateMachineSelector?.PTDAC?.selected?.warranty;
  // if (!slawarrantyData) {
  //   return
  // }
  // let warrantyProds = [];
  // if (slawarrantyData.extendDurationWarrantyTab?.data && warrantySelector?.data?.options?.PTDAC?.warranty[1]) {
  //   warrantyProds = slawarrantyData?.extendDurationWarrantyTab?.data?.products;
  // }
  // let slaProds = [];
  // if (slawarrantyData?.plansWarrantyTab?.data?.plans?.byPlan[planselected]) {
  //   slaProds = slawarrantyData?.plansWarrantyTab?.data?.plans?.byPlan[planselected]?.products;
  // }
  // const slawarrantyProds = warrantyProds.concat(slaProds);
  const slaInput = dynamicSelector?.data?.element?.sla_warranty;
  
  // const slawarrantyProds = _.filter(dynamicSelector.data.boq, (obj) => obj.type === 'warranty' || obj.category === 'sla');
  // Take warranty and sla products from cartBoQ Selector as they are the latest BoQ
  const slawarrantyProds = _.filter(cartBoqSelector.data.cart.items, (obj) => obj.type === 'warranty' || obj.category === 'sla');
  const addonArray = [];
  slawarrantyProds.forEach(eachProd => {
    const prodObj = {
      "product_code": eachProd.product_id,
      "quantity": eachProd.quantity,
      "duration": slaInput.subscription_duration_months_opex ? slaInput.subscription_duration_months_opex : (slaInput.subscription_duration_years_capex ? slaInput.subscription_duration_years_capex : slaInput.subscription_duration_months_operating_lease),
      "type": eachProd.type === 'service' ? 'support' : eachProd.type
    }
    if(businessSelector.data.view.PTDAC.commercial_model === "capex") {
      prodObj["extended_years"] = slaInput.extend_warranty_by ? slaInput.extend_warranty_by.value : "NA"
    } else {
      prodObj["extended_month"] = slaInput.extend_warranty_by ? slaInput.extend_warranty_by.value * 12 : "NA"
    }
    addonArray.push(prodObj);
  });
  return addonArray;
}

export const identifySolutionRedirect = (combinations) => {
  // For mining fullMiningType ug & op is allowed for NDAC
  // if (combinations.includes(fullMiningType.byIds.underground.id) && combinations.includes(fullSolType.byIds.ndac.id)) {

  //   return { next: true, redirect: null }//"next"
  // }
  // if (combinations.includes(fullMiningType.byIds.open.id) && combinations.includes(fullSolType.byIds.ndac.id)) {

  //   return { next: false, redirect: toolRedirect.OOT } //"redirect_oot"
  // }
  if (combinations.includes(fullMiningType.byIds.open.id) && combinations.includes(fullSolType.byIds.mpw.id)) {

    return { next: false, redirect: toolRedirect.ENSO } //"redirect_mpw"
  }
  if (combinations.includes(fullMiningType.byIds.underground.id) && combinations.includes(fullSolType.byIds.mpw.id)) {

    return { next: false, redirect: toolRedirect.ENSO } //"redirect_mpw"
  }
  return { next: true, redirect: null }
}

export const identifyMPWRedirect = (combinations) => {
  if (!combinations.includes(fullSolType.byIds.ndac.value)) {
    return { next: false, redirect: toolRedirect.ENSO } //"redirect_mpw"
  }
  return { next: true, redirect: null }
}